import { useEffect, useRef, useState } from "react";

import { useAsyncCurrency } from "@client/src/Components/hooks/useCurrency";
import { useCustomerContext } from "@client/src/Context/CustomerContext";
import useSegmentTrackEvent from "@client/src/utils/useSegmentTrackEvent";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { UpgradeOptions } from "../../Upsell/UpgradeOptions";
import { sendCloudDiagramsUpsellEvent, type SendCloudDiagramsUpsellEventArgs } from "../metrics";
import AvailabilityChips from "./AvailabilityChips";

const UpsellPage = () => {
  const { customer } = useCustomerContext();
  const [showUpgradeOptions, setShowUpgradeOptions] = useState(false);
  const { customerCurrencySymbol } = useAsyncCurrency();
  const { trackEvent } = useSegmentTrackEvent();
  const [upsellEventProperties, setUpsellEventProperties] = useState<SendCloudDiagramsUpsellEventArgs>(
    {} as SendCloudDiagramsUpsellEventArgs
  );
  const isPageViewEventSentRef = useRef(false);

  useEffect(() => {
    if (customer && customerCurrencySymbol && !isPageViewEventSentRef.current) {
      const newUpsellEventProperties = {
        trackEvent,
        customer,
        customerCurrencySymbol,
      };

      setUpsellEventProperties(newUpsellEventProperties);
      sendCloudDiagramsUpsellEvent("Upsell Page Viewed", newUpsellEventProperties);
      isPageViewEventSentRef.current = true;
    }
  }, [customer, customerCurrencySymbol, trackEvent]);

  return (
    <Box>
      <Container maxWidth="lg" sx={{ pb: 8, mt: 4 }}>
        <Grid
          container
          spacing={12}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid size={{ sm: 12, md: 6 }}>
            <Box>
              <Typography
                variant="h1"
                sx={{ fontSize: "36px", lineHeight: "44px", mb: "6px" }}
                data-cy="clouddiagrams-upsell-title"
              >
                Cloud Diagrams
              </Typography>
              <Stack direction="row" spacing={1}>
                <AvailabilityChips />
              </Stack>
              <Typography variant="body1" sx={{ m: "26px 0" }} data-cy="clouddiagrams-upsell-description">
                DoiT Cloud Diagrams gives your technical and security teams a near-real time picture of your cloud
                architecture.
                <br />
                With an always-up-to-date visual of your company's infrastructure, Cloud Diagrams empowers teams to
                trace dependencies during incidents—reducing resolution time from hours to minutes, streamline
                collaboration across technical teams, make informed architecture decisions, and effectively manage cloud
                costs.
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button
                  data-cy="upsell-view-upgrade-options"
                  variant="contained"
                  sx={{ background: "primary.main", width: "199px", whiteSpace: "nowrap" }}
                  size="large"
                  onClick={() => {
                    setShowUpgradeOptions(true);
                    sendCloudDiagramsUpsellEvent("Upgrade Options Button Clicked", upsellEventProperties);
                  }}
                >
                  View upgrade options
                </Button>
                <Button
                  href="https://help.doit.com/docs/governance/cloud-diagrams"
                  target="_blank"
                  variant="outlined"
                  startIcon={<OpenInNewIcon />}
                  component="a"
                  size="large"
                  data-cy="clouddiagrams-upsell-learn-more"
                >
                  Learn more
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <UpgradeOptions
        featureName="Cloud Diagrams"
        open={showUpgradeOptions}
        onClose={() => {
          setShowUpgradeOptions(false);
        }}
        sendUpsellEvent={() => {
          sendCloudDiagramsUpsellEvent("Upsell Notification Clicked", upsellEventProperties);
        }}
      />
    </Box>
  );
};

export default UpsellPage;
