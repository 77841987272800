import { Stack, Typography } from "@mui/material";

import { type Insight } from "../Insights/types";
import { useInsightThreads } from "./api";
import LinkedThread from "./LinkedThread/LinkedThread";
import { type EnrichedThread } from "./types";
import { useEnrichedThreads } from "./utils";

type Props = {
  insight: Insight;
};

const statusOrder = {
  backlog: 0,
  "to do": 1,
  "in progress": 2,
  done: 3,
};

const getStatusOrderValue = (thread: EnrichedThread) => {
  const status = thread.jiraIssue?.data?.status.toLowerCase();
  return statusOrder[status as keyof typeof statusOrder] ?? -1;
};

const InsightThreads: React.FC<Props> = ({ insight }) => {
  const { threads } = useInsightThreads(insight.key);
  const enrichedThreads = useEnrichedThreads(threads);

  if (threads === null || threads === undefined || enrichedThreads === null || enrichedThreads === undefined) {
    return null;
  }

  const sortedThreads = enrichedThreads.sort((a, b) => getStatusOrderValue(a) - getStatusOrderValue(b));

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          pt: 1.4,
          pb: 1.4,
          fontWeight: 500,
        }}
      >
        Linked threads ({sortedThreads.length})
      </Typography>
      <Stack spacing={1}>
        {sortedThreads.map((thread) => (
          <LinkedThread key={thread.id} thread={thread} />
        ))}
      </Stack>
    </>
  );
};

export default InsightThreads;
