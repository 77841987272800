import { type JSX, type MouseEvent, useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { IncludedWithPlanInfo } from "../../../Components/IncludedWithPlanInfo/IncludedWithPlanInfo";
import { ContactSalesButton } from "../../../Components/NoEntitlement/NoEntitlement";
import HtmlTooltip from "../../../Components/Tooltip/HtmlTooltip";
import { type Insight } from "../../Insights/types";
import { useJiraApi } from "../../Integrations/Jira/useJiraApi";
import { IntroducingThreadsModal } from "../IntroducingThreadsModal";
import { CreateThreadModal } from "./CreateThreadModal";
import { ThreadsTooltip } from "./ThreadsTooltip";

type Props = {
  isEntitled: boolean;
  insight: Insight;
  isFirstThread?: boolean;
};

export const CreateThread = ({ isEntitled, insight, isFirstThread = false }: Props): JSX.Element => {
  const { jiraInstanceData } = useJiraApi();

  const [isIntroducingThreadsModalVisible, setIsIntroducingThreadsModalVisible] = useState(false);
  const [isCreateThreadModalVisible, setIsCreateThreadModalVisible] = useState(false);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = (e: MouseEvent) => {
    e.preventDefault();
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleCreateThreadClick = () => {
    if (jiraInstanceData?.status === "connected") {
      setIsCreateThreadModalVisible(true);
    } else {
      setIsIntroducingThreadsModalVisible(true);
    }
  };

  return (
    <>
      {jiraInstanceData === undefined ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 6,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {isFirstThread && (
            <>
              <Typography
                variant="body1"
                sx={{
                  pt: 1,
                  fontWeight: 500,
                }}
              >
                Get your team involved
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 0.5,
                  color: "text.primary",
                }}
              >
                Use{" "}
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <span>
                    <HtmlTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={isTooltipOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      slotProps={{
                        tooltip: {
                          sx: { maxWidth: "400px" },
                        },
                      }}
                      title={<ThreadsTooltip isEntitled={isEntitled} />}
                    >
                      <Box
                        sx={{ borderBottom: "1px dotted ", borderColor: "text.primary", cursor: "pointer" }}
                        component="span"
                        onClick={handleTooltipOpen}
                      >
                        DoiT Threads
                      </Box>
                    </HtmlTooltip>
                  </span>
                </ClickAwayListener>{" "}
                to create a Jira ticket from this insight.
              </Typography>
            </>
          )}
          {isEntitled ? (
            <Button
              variant="outlined"
              size="medium"
              sx={{
                mt: 1,
                mb: 1,
              }}
              fullWidth
              onClick={() => {
                handleCreateThreadClick();
              }}
            >
              {isFirstThread ? "Create thread" : "Create new thread"}
            </Button>
          ) : (
            <Box>
              <IncludedWithPlanInfo isIncluded={false} />
              <ContactSalesButton variant="outlined" fullWidth />
            </Box>
          )}
          {isIntroducingThreadsModalVisible && (
            <IntroducingThreadsModal
              onClose={() => {
                setIsIntroducingThreadsModalVisible(false);
              }}
            />
          )}
          {isCreateThreadModalVisible && (
            <CreateThreadModal
              onClose={() => {
                setIsCreateThreadModalVisible(false);
              }}
              insight={insight}
              jiraURL={jiraInstanceData?.url}
            />
          )}
        </>
      )}
    </>
  );
};
