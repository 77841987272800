import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsResourceType } from "@doitintl/cmp-models";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { globalText } from "../../../../assets/texts";
import { allocationTxt } from "../../../../assets/texts/CloudAnalytics/allocation";
import DeleteDialog from "../../../../Components/DeleteDialog";
import { DoitConsoleTitle } from "../../../../Components/DoitConsoleTitle";
import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../../Components/FilterTable/FilterTableSkeleton";
import Hide from "../../../../Components/HideChildren/Hide";
import { useAttributionGroups } from "../../../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import useRouteMatchURL from "../../../../Components/hooks/useRouteMatchURL";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { cloudAnalytics } from "../../../../constants/cypressIds";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useIsFeatureEntitled } from "../../../../Context/TierProvider";
import { useUserContext } from "../../../../Context/UserContext";
import { type AttributionWRef } from "../../../../types";
import mixpanel from "../../../../utils/mixpanel";
import useSegmentTrackEvent from "../../../../utils/useSegmentTrackEvent";
import { useUserEmailNotification } from "../../../UserView/UserViewTabs/useUserEmailNotification";
import { type AttributionGroupWithRef } from "../../attributionGroups/types";
import { useAttributionsWithEKSDataCheck } from "../../attributions/hooks";
import { useLabels } from "../../labels/hooks";
import { isOwner, useCanEditSelectedObjectsPermissions } from "../../utilities";
import { AllocationAnomalyDetectionDialog } from "../AllocationAnomalyDetectionDialog";
import { AllocationShareDialog } from "../AllocationShareDialog";
import { useDeleteAllocation } from "../DeleteAllocationValidation";
import { useCreateAllocationHandler } from "../hooks";
import { trackAllocationDeleteEvent } from "../utils";
import { filters, headerColumns } from "./allocationBrowserColumns";
import { AllocationRow } from "./AllocationBrowserRow";

export type AttributionDataWithId = { id: string } & (AttributionGroupWithRef["data"] | AttributionWRef["data"]);

type CommonAllocationRowItem = {
  subscribed: boolean;
  owner: string;
};

export type SingleAllocationRowItem = AttributionWRef &
  CommonAllocationRowItem & {
    type: "Single";
  };

export type GroupAllocationRowItem = AttributionGroupWithRef &
  CommonAllocationRowItem & {
    type: "Group";
  };

export type AllocationRowItem = SingleAllocationRowItem | GroupAllocationRowItem;

const { attributions: cypressAttributionIds } = cloudAnalytics;

const isUserOwner = (selected: AllocationRowItem[], email: string) =>
  selected.every((s) => isOwner(email, s.data) && s.data.type === AnalyticsResourceType.CUSTOM);

const isGroupAllocation = (
  allocation: AttributionGroupWithRef | AttributionWRef
): allocation is AttributionGroupWithRef => allocation.ref.parentModel().id === "cloudAnalyticsAttributionGroups";

const getOwner = (attrData: AllocationRowItem["data"]) =>
  attrData.collaborators.find((collaborator) => collaborator.role === "owner")?.email || "";

export default function AllocationBrowser() {
  const { attributions, attributionsLoading } = useAttributionsWithEKSDataCheck();
  const [attributionGroups] = useAttributionGroups();
  const errorSnackbar = useErrorSnackbar();
  const { trackEvent } = useSegmentTrackEvent();

  const isEntitledAnalytics = useIsFeatureEntitled("analytics:attributions");
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { userModel } = useUserContext({ allowNull: false });
  const userDailyDigests = useMemo(() => userModel.dailyDigests?.map((d) => d.id) || [], [userModel.dailyDigests]);
  const [labels, labelsLoading] = useLabels();
  const [selected, setSelected] = useState<AllocationRowItem[]>([]);
  const [operationItems, setOperationItems] = useState<AllocationRowItem[]>([]);

  const routeMatchURL = useRouteMatchURL();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [anomalyDetectionItem, setAnomalyDetectionItem] = useState<SingleAllocationRowItem | null>(null);
  const allocations = useMemo(() => [...attributions, ...attributionGroups], [attributions, attributionGroups]);
  const { deleteAllocation, DeleteValidationDialog } = useDeleteAllocation({
    allocations,
  });

  useEffect(() => {
    mixpanel.track("analytics.allocations.list");
  }, []);

  const handleShareSingleItem = useCallback(
    (row: AllocationRowItem) => () => {
      setOperationItems([row]);
      setShareDialogOpen(true);
    },
    []
  );

  const handleDeleteSelectedItems = useCallback(() => {
    setOperationItems(selected);
    setDeleteDialogOpen(true);
  }, [selected]);

  const handleShareSelectedItems = useCallback(() => {
    setOperationItems(selected);
    setShareDialogOpen(true);
  }, [selected]);

  const handleAnomalyDetectionSingleItem = useCallback(
    (row: AllocationRowItem) => () => {
      if (row.type === "Single") {
        setAnomalyDetectionItem(row);
      }
    },
    []
  );

  const handleDeleteSingleItem = useCallback(
    (row: AllocationRowItem) => () => {
      setSelected([row]);
      setOperationItems([row]);
      setDeleteDialogOpen(true);
    },
    []
  );

  const { updateUserEmailNotification, userEmailNotification } = useUserEmailNotification(userModel.ref);

  const RowWrapper = useCallback(
    ({ row }: { row: AllocationRowItem }) => (
      <AllocationRow
        row={row}
        labels={labels}
        handleDelete={handleDeleteSingleItem(row)}
        handleShare={handleShareSingleItem(row)}
        handleSettings={handleAnomalyDetectionSingleItem(row)}
        userEmailNotification={userEmailNotification}
        updateUserEmailNotification={updateUserEmailNotification}
      />
    ),
    [
      labels,
      handleDeleteSingleItem,
      handleShareSingleItem,
      handleAnomalyDetectionSingleItem,
      userEmailNotification,
      updateUserEmailNotification,
    ]
  );

  const tableAllocations = useMemo<AllocationRowItem[]>(
    () =>
      allocations.map(
        (allocation) =>
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ({
            ...allocation,
            subscribed: userDailyDigests.includes(allocation.ref.id),
            type: isGroupAllocation(allocation) ? "Group" : "Single",
            owner: getOwner(allocation.data),
          }) as AllocationRowItem
      ),
    [userDailyDigests, allocations]
  );

  const notOwner = !isUserOwner(selected, currentUser.email);

  const deleteButtonTooltip = useMemo(() => {
    if (notOwner) {
      return allocationTxt.CANNOT_DELETE;
    }
    if (selected.map((s) => s.data.type).some((s) => s === AnalyticsResourceType.PRESET)) {
      return allocationTxt.ALLOCATION_PRESET_NOT_DELETE;
    }
    return "";
  }, [notOwner, selected]);

  const selectedForDeleteDisabled = useMemo<boolean>(
    () => notOwner || !selected.length || selected?.some((s) => s.data.type === AnalyticsResourceType.PRESET),
    [notOwner, selected]
  );

  const attributionsWithoutPresets = useMemo(() => selected.filter((x) => x.data.type !== "preset"), [selected]);

  const canEditPermissions = useCanEditSelectedObjectsPermissions(
    currentUser.email,
    attributionsWithoutPresets.map((x) => x.data)
  );

  const handleNewAllocation = useCreateAllocationHandler({
    baseUrl: routeMatchURL,
    mixpanelEventName: "analytics.allocations.new",
  });

  const closeSharingDialog = useCallback(() => {
    setShareDialogOpen(false);
  }, [setShareDialogOpen]);

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [setDeleteDialogOpen]);

  const closeSettingsDialog = useCallback(() => {
    setAnomalyDetectionItem(null);
  }, []);

  const handleDeleteAllocation = useCallback(async () => {
    if (!operationItems.length) {
      return;
    }
    const allocationIds = operationItems.map((item) => item.ref.id);

    try {
      await deleteAllocation({ allocationIds });
      trackAllocationDeleteEvent({
        trackEvent,
        allocationIds,
        currentUserEmail: currentUser.email,
      });
      setSelected([]);
      setOperationItems([]);
      setDeleteDialogOpen(false);
    } catch {
      errorSnackbar(allocationTxt.DELETE_ALC_ERROR);
    }
  }, [deleteAllocation, errorSnackbar, operationItems, trackEvent, currentUser.email]);

  if (labelsLoading || attributionsLoading) {
    return (
      <Box
        sx={{
          p: 1,
        }}
      >
        <FilterTableSkeleton />
      </Box>
    );
  }

  if (!labels) {
    return null;
  }

  return (
    <>
      <DoitConsoleTitle pageName="Allocations" />
      <FilterTable<AllocationRowItem>
        showRowsSelection={true}
        onRowsSelected={setSelected}
        tableItems={tableAllocations}
        rowComponent={RowWrapper}
        headerColumns={headerColumns}
        filterColumns={filters}
        filterBarPlaceholder={allocationTxt.FILTER_ALLOCATIONS}
        persistenceKey="cloud_analytics_allocations_v1"
        itemUniqIdentifierField="ref.id"
        defaultSortingColumnValue="data.timeModified"
        toolbarProps={{
          title: allocationTxt.ALLOCATION_TITLE,
          allowToEditColumns: true,
          deleteButton: {
            text: globalText.DELETE,
            onClick: handleDeleteSelectedItems,
            disabled: selectedForDeleteDisabled,
            tooltipTitle: deleteButtonTooltip,
          },
          customSlot: (
            <>
              <Grid>
                <Hide mdDown>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewAllocation}
                    data-cy={cypressAttributionIds.browser.newAttribution}
                    disabled={!isEntitledAnalytics}
                  >
                    {allocationTxt.CREATE_NEW_ALLOCATION}
                  </Button>
                </Hide>
              </Grid>
              <Grid>
                <Button variant="text" onClick={handleShareSelectedItems} disabled={!canEditPermissions}>
                  {globalText.EDIT_PERMISSIONS}
                </Button>
              </Grid>
            </>
          ),
        }}
      />

      {DeleteValidationDialog()}
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          title={allocationTxt.DELETE_SELECTED}
          message={allocationTxt.DELETE_MESSAGE}
          onDelete={handleDeleteAllocation}
          onClose={closeDeleteDialog}
        />
      )}
      {shareDialogOpen && (
        <AllocationShareDialog
          open={shareDialogOpen}
          onClose={closeSharingDialog}
          attributions={operationItems.map((x) => ({ ...x.data, id: x.ref.id }))}
        />
      )}
      {anomalyDetectionItem && (
        <AllocationAnomalyDetectionDialog onClose={closeSettingsDialog} allocation={anomalyDetectionItem} />
      )}
    </>
  );
}
