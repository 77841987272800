import { type Collaborator, Roles } from "@doitintl/cmp-models";

import { type SegmentEventProperties } from "../../../utils/useSegmentTrackEvent";
import { type AnalyticsResourcesAttributionGroups } from "../analyticsResources/types";
import { isOwner } from "../utilities";
import { type AttributionGroupWithRef } from "./types";

export const getAttributionsIDsFromAG = (
  ag: AttributionGroupWithRef | AnalyticsResourcesAttributionGroups | undefined
): string[] => ag?.data.attributions.map((attribution) => attribution.id) ?? [];

export type TrackAllocationEventParams = {
  trackEvent: (eventName: string, properties?: SegmentEventProperties) => void;
  eventName: string;
  ruleCount: number;
  hasUnallocatedCosts: boolean;
  allocationId?: string;
  currentUserEmail?: string;
  collaborators?: Collaborator[];
  additionalProps?: Record<string, unknown>;
};

export const trackAllocationEvent = ({
  trackEvent,
  eventName,
  ruleCount,
  hasUnallocatedCosts,
  allocationId,
  currentUserEmail,
  collaborators,
  additionalProps,
}: TrackAllocationEventParams): void => {
  const props = {
    pageType: "CloudAnalytics",
    pageSubType: "Allocations",
    feature: "Allocations",
    rulesCount: ruleCount,
    allocationNew: true,
    ...(allocationId && {
      allocationId,
      allocationNew: false,
      allocationType: ruleCount > 1 || hasUnallocatedCosts ? "group" : "single",
    }),
    ...(currentUserEmail && {
      ruleOriginalOwner: isOwner(currentUserEmail, {
        collaborators: collaborators || [{ email: currentUserEmail, role: Roles.OWNER }],
      }),
    }),
    ...additionalProps,
  };

  trackEvent(eventName, props);
};

export type TrackAllocationBrowserEventParams = {
  trackEvent: (eventName: string, properties?: SegmentEventProperties) => void;
  eventName: string;
  allocationId?: string;
  ruleCount?: number;
  hasUnallocatedCosts?: boolean;
  collaborators?: Collaborator[];
  currentUserEmail?: string;
  additionalProps?: Record<string, unknown>;
};

export const trackAllocationBrowserEvent = ({
  trackEvent,
  eventName,
  allocationId,
  additionalProps,
  ruleCount = 0,
  collaborators,
  hasUnallocatedCosts = false,
  currentUserEmail,
}: TrackAllocationBrowserEventParams): void => {
  const props = {
    pageType: "CloudAnalytics",
    pageSubType: "AllocationsBrowser",
    feature: "Allocations",
    ...(allocationId && {
      allocationId,
      rulesCount: ruleCount,
      hasUnallocatedCosts,
      allocationType: ruleCount > 1 || hasUnallocatedCosts ? "group" : "single",
      collaborators,
    }),
    ...(currentUserEmail && {
      ruleOriginalOwner: isOwner(currentUserEmail, {
        collaborators: collaborators || [{ email: currentUserEmail, role: Roles.OWNER }],
      }),
    }),
    ...additionalProps,
  };

  trackEvent(eventName, props);
};

export type TrackAllocationDeleteEventParams = {
  trackEvent: (eventName: string, properties?: SegmentEventProperties) => void;
  allocationIds: string[];
  currentUserEmail?: string;
};

export const trackAllocationDeleteEvent = ({
  trackEvent,
  allocationIds,
  currentUserEmail,
}: TrackAllocationDeleteEventParams): void => {
  const props = {
    pageType: "CloudAnalytics",
    pageSubType: "AllocationsBrowser",
    feature: "Allocations",
    allocationIds,
    currentUserEmail,
  };

  trackEvent("Allocations Deleted", props);
};
