import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import { useAwsFeature } from "../../../Settings/AWS/hooks";

interface RequiredPermissionsDialogProps {
  feature: string;
  open: boolean;
  title?: string;
  description?: string;
  onClose: () => void;
}

const RequiredPermissionsDialog = ({ feature, open, onClose, title, description }: RequiredPermissionsDialogProps) => {
  const cloudConnectFeature = useAwsFeature(feature);

  return (
    <Dialog open={open} onClose={onClose} data-cy="clouddiagrams-required-permissions-dialog">
      <DialogTitle>
        <Stack spacing={1}>
          <Typography variant="h1" data-cy="clouddiagrams-required-permissions-title">
            {title ?? "Required permissions"}
          </Typography>
          <DialogContentText>
            {description ?? "The following permissions are required to use this feature."}
          </DialogContentText>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: 450 }}>
        <DialogContentText data-cy="clouddiagrams-required-permissions-content">
          {cloudConnectFeature?.permissions.map((permission) => (
            <Typography
              color="text.primary"
              variant="body2"
              key={permission}
              data-cy={`clouddiagrams-permission-${permission.replace(/[^a-zA-Z0-9]/g, "-")}`}
            >
              - {permission}
            </Typography>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          data-cy="clouddiagrams-required-permissions-close"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequiredPermissionsDialog;
