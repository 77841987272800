import { type ThreadModel } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import { type UseQueryResult } from "@tanstack/react-query";

export type JiraProject = {
  id: string;
  key: string;
  name: string;
};

export type JiraIssueType = {
  id: string;
  name: string;
};

export type JiraFieldValue = {
  id: string;
  name?: string;
  value?: string;
};

export type JiraIssueSchema = {
  type: string;
};

export type JiraField = {
  key: string;
  name: string;
  fieldId: string;
  required: boolean;
  hasDefaultValue: boolean;
  allowedValues: JiraFieldValue[];
  defaultValue: JiraFieldValue;
  schema: JiraIssueSchema;
};

export type JiraIssue = {
  summary: string;
  description: string;
  assignee: string;
  priority: string;
  status: string;
  statusCategory: string;
  project: string;
  issueType: string;
  dueDate: string;
  createdDate: string;
  reporter: string;
  updatedDate: string;
  updatedName: string;
};

export enum JiraStatus {
  TODO = "To Do",
  IN_PROGRESS = "In Progress",
  DONE = "Done",
}

export interface EnrichedThread extends ModelId<ThreadModel> {
  jiraIssue?: UseQueryResult<JiraIssue>;
}

export interface DisconnectError {
  error: string;
  erroredIssueKeys: string[];
}
