import { useEffect } from "react";

import { Link } from "react-router-dom";
import { Box, Card, Chip, Link as MUILink, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import mixpanel from "../../../utils/mixpanel";
import ThreadThreeDotsMenu from "../ThreeDotsMenu/ThreadThreeDotsMenu";
import { type EnrichedThread } from "../types";
import { getThreadChipColorByStatus } from "../utils";
import { ErroredLinkedThread } from "./ErroredLinkedThread";
import { SkeletonLinkedThread } from "./SkeletonLinkedThread";

type Props = {
  thread: EnrichedThread;
};

const LinkedThread: React.FC<Props> = ({ thread }) => {
  const { customer } = useCustomerContext();

  useEffect(() => {
    mixpanel.track("threads.insight-linked.view");
  }, []);

  if (thread?.jiraIssue?.isError) {
    return <ErroredLinkedThread threadJiraUrl={thread.targetData.issueURL} />;
  }

  if (!thread?.jiraIssue?.data) {
    return <SkeletonLinkedThread />;
  }

  const date = new Date(thread.jiraIssue?.data?.createdDate).toLocaleDateString(navigator.language);
  const chipColor = getThreadChipColorByStatus(thread.jiraIssue?.data?.statusCategory);

  return (
    <Card>
      <Box sx={{ p: 2, pr: 1 }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Stack
            spacing={1}
            sx={{
              flexGrow: 1,
            }}
          >
            <MUILink variant="body2" component={Link} to={`/customers/${customer.id}/threads/${thread.id}`}>
              {thread.jiraIssue?.data?.summary}
            </MUILink>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Typography color="textSecondary" sx={{ fontSize: "12px" }}>
                {date}
              </Typography>
              <Chip variant="status" label={thread.jiraIssue?.data?.status} color={chipColor} />
            </Box>
          </Stack>
          <ThreadThreeDotsMenu threadId={thread.id} targetUrl={thread.targetData.issueURL} />
        </Box>
      </Box>
    </Card>
  );
};

export default LinkedThread;
