import { type SegmentEventProperties } from "../../../../../utils/useSegmentTrackEvent";

export const DATADOG_FEATURE = "Datadog";

export const CONNECTION_TEST_EVENT = "Connection Tested";

export const connectionTestEventProperties: SegmentEventProperties = {
  pageType: "Dashboards",
  pageSubtype: `${DATADOG_FEATURE} Lens`,
  feature: DATADOG_FEATURE,
  integration: DATADOG_FEATURE,
};
