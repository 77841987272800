import { UserPermissions } from "@doitintl/cmp-models";
import { Box, Divider, Paper } from "@mui/material";

import { useAuthContext } from "../../../Context/AuthContext";
import { useUserContext } from "../../../Context/UserContext";
import { Threads } from "../../Threads/Threads";
import { type Insight } from "../types";
import { ContactSupport } from "./ContactSupport";
import { InsightStatus } from "./InsightStatus";

type Props = {
  insight: Insight;
  newSupportTicketUrl: string;
  savingsPeriod: string;
  totalSavings: string;
};

export const GetStarted: React.FC<Props> = ({ insight, newSupportTicketUrl, savingsPeriod, totalSavings }) => {
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isDoitEmployee } = useAuthContext();

  const hasThreadsManagerPermission = isDoitEmployee || userRoles.permissions.has(UserPermissions.ThreadsManager);

  return (
    <Paper elevation={2} sx={{ maxWidth: "312px", position: "sticky", top: 149 }}>
      <Box sx={{ p: 3 }}>
        <InsightStatus />
        <Divider sx={{ my: 2 }} />
        <ContactSupport
          newSupportTicketUrl={newSupportTicketUrl}
          insight={insight}
          savingsPeriod={savingsPeriod}
          totalSavings={totalSavings}
        />
        {hasThreadsManagerPermission && (
          <>
            <Divider sx={{ my: 2 }} />
            <Threads insight={insight} />
          </>
        )}
      </Box>
    </Paper>
  );
};
