import { Card, Chip, Radio, Typography, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { type DiagramCreationModeOption } from "./DiagramCreationModeSelector";

interface DiagramCreationModeSelectorOptionProps {
  option: DiagramCreationModeOption;
  onClick: () => void;
  isSelected: boolean;
  disabled?: boolean;
}

const DiagramCreationModeSelectorOption = ({
  option,
  onClick,
  isSelected,
  disabled,
}: DiagramCreationModeSelectorOptionProps) => {
  const theme = useTheme();

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        display: "flex",
        width: "100%",
        borderColor: isSelected ? "primary.main" : theme.palette.general.outlineBorder,
        borderWidth: 1,
        borderRadius: 2,
        cursor: disabled ? "not-allowed" : "pointer",
        px: 2,
        py: 3,
      }}
      data-cy={`clouddiagrams-creation-mode-option-${option.value}`}
    >
      <Stack direction="row" spacing={2} data-cy="clouddiagrams-creation-mode-stack">
        <Box alignContent="start" data-cy="clouddiagrams-creation-mode-radio-container">
          <Radio
            sx={{ p: 0 }}
            size="small"
            color="primary"
            disabled={disabled}
            checked={isSelected}
            data-cy="clouddiagrams-creation-mode-radio"
          />
        </Box>
        <Stack spacing={2} data-cy="clouddiagrams-creation-mode-content">
          <Stack spacing={1} direction="row" data-cy="clouddiagrams-creation-mode-header">
            <Typography variant="subtitle1" fontWeight={500} data-cy="clouddiagrams-creation-mode-title">
              {option.title}
            </Typography>
            {option.recommended && (
              <Chip
                variant="status"
                color="primary"
                label="Recommended"
                data-cy="clouddiagrams-creation-mode-recommended"
              />
            )}
          </Stack>
          <div data-cy="clouddiagrams-creation-mode-description">{option.description}</div>
        </Stack>
      </Stack>
    </Card>
  );
};

export default DiagramCreationModeSelectorOption;
