import { type ChangeEvent, useCallback, useMemo, useState } from "react";

import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { cloudflowTexts } from "../../../assets/texts";
import { CircularProgressLoader } from "../../../Components/Loader";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { consoleErrorWithSentry } from "../../../utils";
import { useCloudflowBlueprints, useCreateCloudflowFromBlueprint } from "../hooks";
import Blueprint from "./Blueprint";
import { BlueprintFilters } from "./BlueprintFilters";
import type { CloudflowBlueprint } from "../types";

const CloudflowBlueprints = () => {
  const { customer } = useCustomerContext();
  const theme = useTheme();
  const history = useHistory();
  const { blueprints, blueprintsLoading } = useCloudflowBlueprints();
  const [createCloudflowFromBlueprint, creatingCloudflowFromBlueprint] = useCreateCloudflowFromBlueprint();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<Record<string, boolean>>({});
  const showErrorSnackbar = useErrorSnackbar();
  const isPresentationMode = isCustomerInPresentationMode(customer);
  const customerId = customer.id;

  const onFiltersChange = (filter: object) => {
    setSelectedFilters({ ...selectedFilters, ...filter });
  };

  const returnToDashboard = (): void => {
    history.push(`/customers/${customer.id}/cloudflow`);
  };

  const onBlueprintClick = useCallback(
    async (blueprint: CloudflowBlueprint) => {
      try {
        if (isPresentationMode) {
          history.push(`/customers/${customerId}/blueprint/${blueprint.id}`);
          return;
        }
        const res = await createCloudflowFromBlueprint(customerId, blueprint);

        if (res) {
          history.push(`/customers/${customerId}/cloudflow/edit/${res.id}`);
        }
      } catch (e) {
        showErrorSnackbar("Could not create cloudflow from blueprint");
        consoleErrorWithSentry(e);
      }
    },
    [createCloudflowFromBlueprint, customerId, history, isPresentationMode, showErrorSnackbar]
  );

  const handleSearchChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredBlueprints = useMemo(
    () =>
      blueprints?.filter((blueprint) => {
        const inSearchQuery = searchQuery
          ? blueprint.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            blueprint.description.toLowerCase().includes(searchQuery.toLowerCase())
          : true;

        const checkboxFiltersActive = selectedFilters && Object.values(selectedFilters).some((filter) => filter);
        if (!checkboxFiltersActive) return inSearchQuery;

        if (!blueprint.tags) return false;

        const inFilters = Object.entries(selectedFilters).every(([filter, isActive]) => {
          if (!isActive) return true;
          return Object.entries(blueprint.tags!).some(([tagGroup, tagValues]) =>
            tagValues.some((tag) => filter === `${tagGroup}:${tag}`)
          );
        });

        return inSearchQuery && inFilters;
      }),
    [searchQuery, selectedFilters, blueprints]
  );

  if (blueprintsLoading) {
    return <CircularProgressLoader />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          pt: 1,
        }}
      >
        <IconButton aria-label="Back to blueprints list" onClick={returnToDashboard}>
          <ArrowBackIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <Typography variant="h1" data-cy="title" sx={{ fontWeight: "fontWeightMedium" }}>
          {cloudflowTexts.BLUEPRINTS.PAGE_TITLE}
        </Typography>
      </Stack>
      <Stack direction={{ md: "column", lg: "row" }} spacing={2}>
        <BlueprintFilters blueprints={blueprints} onFiltersChange={onFiltersChange} selectedFilters={selectedFilters} />
        <Stack
          direction="column"
          sx={{
            width: "100%",
          }}
        >
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search blueprints"
            autoFocus
            onChange={handleSearchChange}
            sx={{ mb: 2 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start" component="div">
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
          {filteredBlueprints && filteredBlueprints.length > 0 ? (
            <Grid
              container
              spacing={2}
              sx={{
                alignContent: "flex-start",
                "&.MuiGrid-container": { ml: -2 },
              }}
            >
              {filteredBlueprints.map((blueprint) => (
                <Grid
                  key={blueprint.id}
                  sx={{ pl: 0 }}
                  size={{
                    lg: 4,
                    md: 6,
                    sm: 12,
                  }}
                >
                  <Blueprint
                    key={blueprint.id}
                    blueprint={blueprint}
                    onBlueprintClick={onBlueprintClick}
                    clouds={blueprint.tags?.cloud || []}
                    disabled={creatingCloudflowFromBlueprint}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Stack
              direction="column"
              spacing={2.5}
              sx={{
                height: "50vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h1">No results</Typography>
              <Typography
                color="textSecondary"
                sx={{
                  textAlign: "center",
                }}
              >
                No blueprints match the search criteria, try different filters.
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CloudflowBlueprints;
