import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import * as runtime from "react/jsx-runtime";
import { ErrorBoundary } from "react-error-boundary";
import { InsightModel, Metadata, TimeInterval, type TimeSettingsConfig, TimeSettingsMode } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import { compile, run } from "@mdx-js/mdx";
import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import orderBy from "lodash/orderBy";

import { useApiContext } from "../../api/context";
import { useAsyncCurrency } from "../../Components/hooks/useCurrency";
import useGenerateReport from "../../Components/hooks/useGenerateReport";
import { queryKeys } from "../../constants";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type RequestConfig } from "../../Pages/CloudAnalytics/generateReport/types";
import { consoleErrorWithSentry } from "../../utils";
import { getInsights, getInsightsMissingPermissions, useAccounts, useCustomerPayerAccounts } from "./api";
import {
  type AccessType,
  type Insight,
  type InsightMissingPermissionsResponse,
  InsightProviderId,
  type InsightResultsResponse,
  type NoAccessInsight,
  type NonEntitledInsightsResults,
  type ServerInsight,
} from "./types";
import { normalizeInsightCategories } from "./utils";

export const useMdxContent = (mdxStringifyContent: string) => {
  const [content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const compileMdx = async () => {
      const code = String(await compile(mdxStringifyContent, { outputFormat: "function-body" }));
      const { default: Content } = await run(code, { ...runtime, Fragment: Box } as any);

      setContent(
        <ErrorBoundary
          fallback={<p>Error showing content, </p>}
          onError={(error) => {
            consoleErrorWithSentry(error);
          }}
        >
          <Content
            components={{
              // Any headings within the text should be formatted the same way
              h1: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h2: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h3: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h4: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h5: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h6: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              p: ({ children, style }) => (
                <Typography variant="body1" style={style} sx={{ mb: 2 }}>
                  {children}
                </Typography>
              ),
              a: ({ children, href }) => (
                <Link target="_blank" href={href}>
                  {children}
                </Link>
              ),
              Table: (props) => <Table sx={{ mb: 2 }} {...props} />,
              TableHead: (props) => <TableHead {...props} />,
              TableBody: (props) => <TableBody {...props} />,
              TableRow: (props) => <TableRow {...props} />,
              TableCell: (props) => <TableCell {...props} />,
            }}
          />
        </ErrorBoundary>
      );
    };

    compileMdx().catch((e) => {
      setContent(e.message);
    });
  }, [mdxStringifyContent]);

  return content;
};

export const defaultTimeSettingsConfig: TimeSettingsConfig = {
  amount: 30,
  mode: TimeSettingsMode.Last,
  unit: TimeInterval.DAY,
  includeCurrent: true,
};

type InsightsState = {
  insights: Insight[] | undefined;
  nonEntitledSummary: NonEntitledInsightsResults | undefined;
  noAccessResults?: NoAccessInsight[];
  isLoading: boolean;
  error: Error | null;
};

export const useInsights = (): InsightsState => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const api = useApiContext();
  const { isDoitEmployee } = useAuthContext();
  const { asyncConvertCurrency } = useAsyncCurrency();
  const [noAccessInsightsWithCurrency, setNoAccessInsightsWithCurrency] = useState<NoAccessInsight[] | undefined>();

  const { isFetching, data, error } = useQuery<
    InsightResultsResponse<ServerInsight>,
    AxiosError,
    InsightResultsResponse
  >({
    queryKey: [customer.id, queryKeys.insights],
    queryFn: () => getInsights(api, { customerId: customer.id }),
    select: (data) => {
      const normalizedResults = normalizeInsightCategories(data.results);
      return { ...data, results: normalizedResults };
    },
    retry: 1,
    retryDelay: 1000,
    retryOnMount: false,

    // Keep the data for an hour for now, as it's unlikely to change often - this way,
    // the user can navigate between insights without getting a loading indicator
    staleTime: 60 * 60 * 1000,
  });

  const [insightsStatusChanges] = useCollectionData(
    getCollection(InsightModel).doc("api").collection("insightsStatusChanges").where("customerId", "==", customer.id)
  );

  useEffect(() => {
    if (!data?.noAccessResults) {
      return;
    }

    const fn = async (insight) => {
      if (!insight.potentialDailySavings) {
        return insight;
      }

      return {
        ...insight,
        potentialDailySavings: {
          ...insight.potentialDailySavings,
          value: await asyncConvertCurrency(insight.potentialDailySavings.value),
        },
      };
    };

    Promise.all(data.noAccessResults.map(fn)).then((noAccessInsightsWithUpdatedCurrency) => {
      setNoAccessInsightsWithCurrency(noAccessInsightsWithUpdatedCurrency);
    });
  }, [asyncConvertCurrency, data?.noAccessResults]);

  const insightsWithStatuses = useMemo(() => {
    if (!data?.results) {
      return [];
    }

    if (!insightsStatusChanges) {
      return data.results;
    }

    return data.results.map((insight) => {
      const matchingStatusChangeDocument = insightsStatusChanges.find(
        (statusChange) => statusChange.insightRef.id === `${insight.customerId}#${insight.providerId}#${insight.key}`
      );

      if (!matchingStatusChangeDocument) {
        const status = insight.displayStatus;

        return {
          ...insight,
          userStatusChanges: { status } as any,
        };
      }
      const sortedStatusChanges = orderBy(matchingStatusChangeDocument.statusChanges, ["timestamp.seconds"], ["desc"]);
      const userStatusChanges = sortedStatusChanges[0];

      return {
        ...insight,
        userStatusChanges,
      };
    });
  }, [data?.results, insightsStatusChanges]);

  // Double safety - the API does not return internal insights for non-DoiT employees,
  // but just in case something goes wrong there, filter them again
  const finalInsights = useMemo(() => {
    if (!isDoitEmployee) {
      return insightsWithStatuses.filter((insight) => !insight.isInternal);
    }
    return insightsWithStatuses;
  }, [insightsWithStatuses, isDoitEmployee]);

  // We are loading if the query is still fetching or if we have data, but have not yet processes the insightStatusChanges
  const isLoading = isFetching || (data !== undefined && insightsStatusChanges === undefined);

  if (isLoading || !data || finalInsights.length === 0) {
    return {
      insights: undefined,
      nonEntitledSummary: undefined,
      noAccessResults: noAccessInsightsWithCurrency,
      error,
      isLoading,
    };
  }

  return {
    insights: finalInsights,
    nonEntitledSummary: data.nonEntitledResultsSummary,
    noAccessResults: noAccessInsightsWithCurrency,
    error,
    isLoading,
  };
};

type UseAccessType = {
  trustedAdvisor?: AccessType;
};

export const useAccessType = (insights?: Insight[]): UseAccessType => {
  const trustedAdvisorAccessType = useMemo(() => {
    const trustedAdvisorInsight = insights?.find((insight) => insight.providerId === InsightProviderId.TRUSTED_ADVISOR);
    return trustedAdvisorInsight?.permissions?.accessType;
  }, [insights]);

  return {
    trustedAdvisor: trustedAdvisorAccessType,
  };
};

export const useInsightsInvestigation = ({ insightName }) => {
  const { customer } = useCustomerContext();
  const generateReport = useGenerateReport();
  const formattedCurrentTimestamp = new Date().toLocaleString();
  return useCallback(
    async ({ config }: { config: RequestConfig }) => {
      const params = {
        name: `${insightName} report - ${formattedCurrentTimestamp}`,
        config: {
          ...config,
          // If no type is provided, default to `fixed, which is a "normal" field
          fields: config?.fields.map((field) => ({ ...field, type: field.type ? field.type : Metadata.FIXED })),
          timeSettings: defaultTimeSettingsConfig,
        } satisfies RequestConfig,
        description: "",
      };

      const reportId = await generateReport(params, false);
      return `/customers/${customer.id}/analytics/reports/${reportId}?run-on-open=true`;
    },
    [customer.id, generateReport, insightName, formattedCurrentTimestamp]
  );
};

type Permissions = {
  relevantAccountsWithoutPermissions: string[];
  hasNoAccessToRelevantAccounts: boolean;
};

export const useRelevantAccountsWithoutCostOptPermissions = (): Permissions => {
  const [connectAccounts] = useAccounts();
  const payerAccounts = useCustomerPayerAccounts();

  const { relevantAccountsWithoutPermissions, hasNoAccessToRelevantAccounts } = useMemo(() => {
    if (!connectAccounts) return { relevantAccountsWithoutPermissions: [], hasNoAccessToRelevantAccounts: false };

    const filteredAccounts = connectAccounts.filter((account) => payerAccounts.includes(account.accountId));

    const relevantAccountsWithPermissions = filteredAccounts
      .filter((acc) =>
        acc.supportedFeatures?.some(
          (feat) => feat.name === "cost-optimization-hub-insights" && feat.hasRequiredPermissions
        )
      )
      .map((acc) => acc.accountId);

    const relevantAccountsWithoutPermissions = payerAccounts.filter(
      (acc) => !relevantAccountsWithPermissions.includes(acc)
    );

    return {
      relevantAccountsWithoutPermissions,
      hasNoAccessToRelevantAccounts: relevantAccountsWithoutPermissions.length === payerAccounts.length,
    };
  }, [connectAccounts, payerAccounts]);

  return { relevantAccountsWithoutPermissions, hasNoAccessToRelevantAccounts };
};

type PermissionsByService = Record<InsightProviderId, string[]>;

type UseAccountsWithMissingPermissions = {
  trustedAdvisorAccounts: string[];
  isLoading: boolean;
  error: Error | null;
};

const emptyAccountsArray: string[] = [];

export const useAccountsWithMissingPermissions = (): UseAccountsWithMissingPermissions => {
  const api = useApiContext();
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  const { isFetching, data, error } = useQuery<InsightMissingPermissionsResponse, AxiosError, PermissionsByService>({
    queryKey: [customer.id, queryKeys.insightsMissingPermissions],
    queryFn: () => getInsightsMissingPermissions(api, { customerId: customer.id }),
    select: (data) =>
      // Creating PermissionsByProvider structure
      data.reduce((acc, item) => {
        const { providerId, scopes } = item;
        if (!acc[providerId]) {
          acc[providerId] = scopes;
        }
        return acc;
      }, {} as PermissionsByService),
    retry: 1,
    retryDelay: 1000,
    retryOnMount: false,
  });

  return {
    trustedAdvisorAccounts: data?.[InsightProviderId.TRUSTED_ADVISOR] || emptyAccountsArray,
    isLoading: isFetching,
    error,
  };
};
