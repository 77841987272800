import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { type InsightUserStatus } from "@doitintl/cmp-models";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import capitalize from "lodash/capitalize";

import { useApiContext } from "../../../api/context";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { queryKeys } from "../../../constants";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { useFullScreen } from "../../../utils/dialog";
import { useInsightsContext } from "../context";

const NOT_RELAVANT = "not relevant";
const NOT_ENOUGH_INFORMATION = "not enough information";
const NOT_WORTH_THE_EFFORT = "not worth the effort";
const INACCURATE_OPTIMIZATION_OPPORTUNITIES = "inaccurate optimization opportunities";

const DismissInsightModal = ({ onClose }: { onClose: () => void }) => {
  const { selectedInsight, setSelectedInsight } = useInsightsContext();

  const { isMobile: isSmallScreen } = useFullScreen("sm");
  const { userId } = useAuthContext();
  const { customer } = useCustomerContext();
  const api = useApiContext();

  const showErrorSnackbar = useErrorSnackbar();

  const [reasons, setReasons] = useState({
    [NOT_RELAVANT]: false,
    [NOT_ENOUGH_INFORMATION]: false,
    [NOT_WORTH_THE_EFFORT]: false,
    [INACCURATE_OPTIMIZATION_OPPORTUNITIES]: false,
  });
  const [comment, setComment] = useState("");

  const handleDismiss = async () => {
    onClose();

    const data = {
      customerId: customer.id,
      providerId: selectedInsight?.providerId,
      key: selectedInsight?.key,
      status: "dismissed",
      userId,
      dismissalDetails: {
        reasons: Object.entries(reasons)
          .filter(([_, value]) => value)
          .map(([key]) => key),
        comment,
      },
    };

    try {
      await api.post("insights/status", data);

      setSelectedInsight((insight) => {
        if (!insight) {
          return insight;
        }

        return {
          ...insight,
          userStatusChanges: insight.userStatusChanges && {
            ...insight.userStatusChanges,
            status: "dismissed",
          },
        };
      });
    } catch (e) {
      consoleErrorWithSentry(e);
      showErrorSnackbar("Something went wrong. Please try again later.");
    }
  };

  const theme = useTheme();

  return (
    <Dialog open={true} fullScreen={isSmallScreen} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Dismiss</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Please select the reason(s) why you’re dismissing this insight. Mark all that apply:
        </Typography>
        <Typography sx={{ my: 0.5 }}>
          <Checkbox
            checked={reasons[0]}
            onChange={() => {
              setReasons((prevState) => ({
                ...prevState,
                "not relevant": true,
              }));
            }}
          />
          Not relevant
        </Typography>
        <Typography sx={{ my: 0.5 }}>
          <Checkbox
            checked={reasons[1]}
            onChange={() => {
              setReasons((prevState) => ({
                ...prevState,
                "not enough information": true,
              }));
            }}
          />
          Not enough information
        </Typography>
        <Typography sx={{ my: 0.5 }}>
          <Checkbox
            checked={reasons[2]}
            onChange={() => {
              setReasons((prevState) => ({
                ...prevState,
                "not worth the effort": true,
              }));
            }}
          />
          Not worth the effort
        </Typography>
        <Typography sx={{ my: 0.5 }}>
          <Checkbox
            checked={reasons[3]}
            onChange={() => {
              setReasons((prevState) => ({
                ...prevState,
                "inaccurate optimization opportunities": true,
              }));
            }}
          />
          Inaccurate optimization opportunities
        </Typography>
        <TextField
          label="Additional comments (optional)"
          variant="outlined"
          key="description"
          fullWidth
          multiline
          minRows={5}
          sx={{ mt: 2, mb: 1 }}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
        <Typography variant="caption" sx={{ mb: 2, color: theme.palette.text.secondary }}>
          * After dismissing it, the Insight will be moved to the list of dismissed Insights, in case you need to revert
          this action.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button variant="contained" disabled={Object.values(reasons).every((r) => !r)} onClick={handleDismiss}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const InsightStatusThreeDotMenu = () => {
  const { selectedInsight, setSelectedInsight } = useInsightsContext();
  const queryClient = useQueryClient();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { customer } = useCustomerContext();

  const history = useHistory();

  const open = Boolean(anchorEl);

  const api = useApiContext();

  const { userId, currentUser, isDoitEmployee } = useAuthContext();
  const showErrorSnackbar = useErrorSnackbar();

  const [isDismissModalVisible, setIsDismissModalVisible] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusUpdate = async (newStatus: string) => {
    handleClose();

    if (newStatus === "dismissed") {
      setIsDismissModalVisible(true);
    } else {
      const data = {
        customerId: customer.id,
        providerId: selectedInsight?.providerId,
        key: selectedInsight?.key,
        status: newStatus,
        userId,
      };

      try {
        await api.post("insights/status", data);

        // refresh the insights
        queryClient.invalidateQueries({ queryKey: [customer.id, queryKeys.insights] });

        setSelectedInsight((insight) => {
          if (!insight) {
            return insight;
          }

          return {
            ...insight,
            userStatusChanges: insight.userStatusChanges && {
              ...insight.userStatusChanges,
              status: newStatus as InsightUserStatus,
            },
          };
        });
      } catch (e) {
        consoleErrorWithSentry(e);
        showErrorSnackbar("Something went wrong. Please try again later.");
      }
    }
  };

  const handleMenuOptionClick = (status: string) => {
    if (status === "edit") {
      const newInsightUrl = `/customers/${customer.id}/insights/new-insight/${selectedInsight?.key}`;

      history.push(newInsightUrl);
    } else {
      handleStatusUpdate(status);
    }
  };

  const [menuOptions, setMenuOptions] = useState<{ label: string; status: string }[]>([]);

  useEffect(() => {
    const menuOptionOptimized = { label: "Mark as optimized", status: "optimized" };
    const menuOptionNotOptimized = { label: "Mark as actionable", status: "actionable" };
    const menuOptionDismiss = { label: "Dismiss", status: "dismissed" };
    const menuOptionEdit = { label: "Edit", status: "edit" };
    const shared = isDoitEmployee ? [menuOptionEdit] : [];

    const newMenuOptions =
      selectedInsight?.userStatusChanges?.status === "actionable"
        ? [menuOptionOptimized, menuOptionDismiss, ...shared]
        : [menuOptionNotOptimized, ...shared];

    setMenuOptions(newMenuOptions);
  }, [selectedInsight, currentUser?.email, isDoitEmployee]);

  return (
    <>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="actions"
        onClick={handleClick}
        size="medium"
        sx={{ mb: "-4px" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuOptions.map(({ label, status }) => (
          <MenuItem
            key={status}
            onClick={() => {
              handleMenuOptionClick(status);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
      {isDismissModalVisible && (
        <DismissInsightModal
          onClose={() => {
            setIsDismissModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export const InsightStatus = () => {
  const { selectedInsight } = useInsightsContext();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="subtitle2"
          sx={{
            color: "text.secondary",
          }}
        >
          Status
        </Typography>
        <Typography variant="h3" sx={{ mb: 1 }}>
          {capitalize(selectedInsight?.userStatusChanges?.status ?? "actionable")}
        </Typography>
      </Box>
      {selectedInsight?.providerId === "custom" && <InsightStatusThreeDotMenu />}
    </Box>
  );
};
