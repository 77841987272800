import { useEffect, useState } from "react";

import { useApiContext } from "@client/src/api/context";
import { useCustomerContext } from "@client/src/Context/CustomerContext";

import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import DashboardHead from "./DashboardHead";
import DashboardList, { getDiagramList } from "./DashboardList";
import DefaultPage from "./DefaultPage";
import UpsellPage from "./UpsellPage";

interface DiagramItem {
  _id: string;
  ss_id: string;
  name?: string;
  account_name?: string;
  account_type?: string;
  changes?: Array<{
    type: string;
    count: number;
    service: string;
  }>;
}

const CloudDiagramsDashboard = () => {
  const isEntitled = useIsFeatureEntitled("governance:cloud-diagrams");

  const [searchQuery, setSearchQuery] = useState("");
  const [diagramList, setDiagramList] = useState<null | DiagramItem[]>(null);

  const api = useApiContext();
  const { customer } = useCustomerContext();

  useEffect(() => {
    if (!isEntitled) setDiagramList([]);
    else getDiagramList(api, customer, setDiagramList);
  }, [api, customer, isEntitled]);

  return !diagramList ? null : !isEntitled ? (
    <UpsellPage />
  ) : diagramList.length > 0 ? (
    <div data-cy="clouddiagrams-dashboard-container">
      <DashboardHead onSearch={setSearchQuery} />
      <DashboardList searchQuery={searchQuery} diagramList={diagramList} />
    </div>
  ) : (
    <DefaultPage />
  );
};

export default CloudDiagramsDashboard;
