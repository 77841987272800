import React, { useState } from "react";

import { type Cluster, KubernetesFeatureStatus } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import ConnectedClustersDialog from "./ConnectedClustersDialog";
import DiagramCreationModeSelectorOption from "./DiagramCreationModeOption";

export enum DiagramCreationMode {
  CREATE_WITHOUT_CLUSTERS = "CREATE_WITHOUT_CLUSTERS",
  CREATE_WITH_CLUSTERS = "CREATE_WITH_CLUSTERS",
}

export interface DiagramCreationModeOption {
  value: DiagramCreationMode;
  title: string;
  description: React.ReactNode;
  recommended?: boolean;
}

interface DiagramCreationModeSelectorProps {
  value: DiagramCreationMode;
  onChange: (value: DiagramCreationMode) => void;
  accountWasAlreadyConnected?: boolean;
  accountClusters: Cluster[];
}

const DiagramCreationModeSelector = ({
  value,
  onChange,
  accountWasAlreadyConnected,
  accountClusters,
}: DiagramCreationModeSelectorProps) => {
  const [connectedClustersDialogOpen, setConnectedClustersDialogOpen] = useState(false);

  const k8sFeatureKey = "clouddiagrams";
  const connectedClusters = accountClusters.filter(
    (c) =>
      c.features?.[k8sFeatureKey]?.isActivated &&
      c.features?.[k8sFeatureKey]?.status === KubernetesFeatureStatus.HEALTHY
  );

  const handleViewClustersTextClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setConnectedClustersDialogOpen(true);
    e.stopPropagation();
  };

  const options: DiagramCreationModeOption[] = [
    {
      value: DiagramCreationMode.CREATE_WITH_CLUSTERS,
      title: connectedClusters.length > 0 ? "Discover new EKS clusters" : "Show EKS clusters on your diagram",
      description: (
        <Typography variant="body1">
          {connectedClusters.length > 0
            ? "Discover additional EKS clusters in your account and grant access to show detailed Kubernetes resources like pods and deployments in your diagram."
            : "Discover EKS clusters in your account and grant access to show detailed Kubernetes resources like pods and deployments in your diagram."}
        </Typography>
      ),
      recommended: true,
    },
    {
      value: DiagramCreationMode.CREATE_WITHOUT_CLUSTERS,
      title: (() => {
        if (connectedClusters.length > 0) {
          const clusterText = `connected EKS cluster${connectedClusters.length > 1 ? "s" : ""}`;
          return `Create diagram with ${connectedClusters.length} ${clusterText}`;
        }
        return "Create diagram without EKS clusters";
      })(),
      description: (
        <Typography variant="body1">
          {connectedClusters.length > 0 ? (
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body1">
                {accountWasAlreadyConnected
                  ? "You can discover and connect new clusters at any time."
                  : "Visualize your infrastructure without EKS clusters. You can enable EKS clusters later."}
              </Typography>
              <Typography color="primary" onClick={handleViewClustersTextClick}>
                View cluster{connectedClusters.length > 1 ? "s" : ""}
              </Typography>
            </Stack>
          ) : (
            "Visualize your infrastructure without EKS clusters. You can enable EKS clusters later."
          )}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <ConnectedClustersDialog
        clusters={connectedClusters}
        open={connectedClustersDialogOpen}
        onClose={() => {
          setConnectedClustersDialogOpen(false);
        }}
      />
      <Stack spacing={2} data-cy="clouddiagrams-creation-mode-options">
        {options.map((option) => (
          <DiagramCreationModeSelectorOption
            key={option.value}
            onClick={() => {
              onChange(option.value);
            }}
            option={option}
            isSelected={value === option.value}
          />
        ))}
      </Stack>
    </>
  );
};

export default DiagramCreationModeSelector;
